.fullscreen-carousel {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100vh;
    line-height: 1.5;
    font-size: 1.5em;
    color: #f5f5f5;
}
.fullscreen-carousel img {
    width: 100vw;
    height: 100vh;
}

.carousel .control-dots {
    bottom: auto;
    top: 50%;
    transform: translateY(-50%);
    z-index: 3;
}

.carouselIndicator {
    margin: 10px 0px 10px 93vw;
    text-align: center;
    font-size: 28px;
    position: relative;
}
.carouselIndicator::before {
    content: "";
    height: 12px;
    border-left: solid 1px #A9A9A9;
    position: absolute;
    z-index: 1;
    top: -2px;
    left: 50%;
    transform: translateX(-50%);
}
.carouselIndicator.active::before {
    border-left: solid 1px #F3F6FB;
}
.carouselIndicator::after {
    content: "";
    height: 12px;
    border-left: solid 1px #A9A9A9;
    position: absolute;
    z-index: 1;
    bottom: -8px;
    right: 50%;
    transform: translateX(50%);
}
.carouselIndicator.active::after {
    border-left: solid 1px #F3F6FB;
}
