$primary: #049cf4;
$danger: #cc040c;
$light: #f8f9fa;
$dark: #404040;
$btn-focus-box-shadow: none !important;

@import "react-slideshow-image/dist/styles.css";
@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap");
@import "~bootstrap/scss/bootstrap";
@import "~animate.css/animate.min.css";

* {
  transition-duration: 300ms;
}

.pointer {
  cursor: pointer;
}

body,
html {
  font-family: "Open Sans", sans-serif;
  overflow-x: hidden;
}

textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
[type="text"].form-control:focus,
[type="password"].form-control:focus,
[type="email"].form-control:focus,
[type="tel"].form-control:focus,
[contenteditable].form-control:focus {
  box-shadow: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

input:-webkit-autofill {
  -webkit-text-fill-color: #072366 !important;
}

.text-no-decor {
  text-decoration: none !important;
}

.minimal-text {
  color: #f8f9fa;
  font-weight: 300;
  letter-spacing: 0.5rem;
  margin-bottom: 0;
  opacity: 0.8;
  font-size: 0.8rem;
}

.minimal-index {
  color: #f8f9fa;
  font-weight: 300;
  letter-spacing: 0.5rem;
  margin-bottom: 0;
  opacity: 0.8;
  font-size: 0.8rem;
}

.minimal-index::before,
.minimal-index::after {
  display: inline-block;
  content: "";
  height: 0.4rem;
  border-top: 1px solid;
  width: 2rem;
  margin: 0 0.5rem;
}
.minimal-index::after {
  margin: 0 0 0 0rem;
}

.widen {
  letter-spacing: 0.1rem;
}

.widen-lg {
  letter-spacing: 0.2rem;
}

.yearLeft50 {
  left: 15vw;
  @media (max-width: 768px) {
    left: 50%;
    transform: translate(-52%) translateY(-55%) !important;
  }
}

.text-gradient {
  background: linear-gradient(to right, #049cf4 0%, #cc040c 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bg-gradient {
  background-image: linear-gradient(to right, #049cf4 0%, #cc040c 100%) !important;
}

.hoverLink {
  background-color: rgba($color: #000000, $alpha: 0.4);
}

.hoverLink:hover {
  background-color: rgba($color: #000000, $alpha: 0.1);
}

.hoverBox:hover {
  transform: scale(1.05);
}

.hoverLink > fieldset {
  border-color: #f8f9fa !important;
}

.hoverLink > fieldset > legend {
  color: #f8f9fa !important;
}

.hoverLink:hover > fieldset > legend {
  // background: linear-gradient(to top left, #049cf4 0%, #cc040c 100%);
  // -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;
}

figure::before {
  position: absolute;
  top: 0;
  left: -10%;
  z-index: 1;
  display: none;
  content: "";
  width: 10%;
  height: 100%;
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
  -webkit-transform: skewX(-25deg);
  transform: skewX(-25deg);
  opacity: 0;
}

.hoverBox:hover > figure::before {
  -webkit-animation: shine 0.75s;
  animation: shine 0.75s;
  display: block;
}

@-webkit-keyframes shine {
  100% {
    left: 125%;
    opacity: 1;
  }
}
@keyframes shine {
  100% {
    left: 125%;
    opacity: 1;
  }
}

.no-list {
  list-style: none;
}

.no-list > a {
  margin-bottom: 0.25rem;
}

.each-slide > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: clamp(350px, 30vw, 450px);
}

.each-slide span {
  padding: 0.5rem;
  width: 100%;
  bottom: 0;
  margin-top: auto;
  font-size: 1.4rem;
  background: rgba(0, 0, 0, 0.4);
  text-align: center;
  color: #f8f9fa;
}

.react-slideshow-container > button {
  fill: black;
  background-color: transparent !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hide {
  display: none !important;
}